import React, { Fragment, useEffect, useRef, useState } from "react";
import Icons from "../utils/Icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getOpenUseNoticationAction,
  getOpenUserChatListAction,
} from "../../services/action/common";
import { formatMessageTime } from "../utils/functions/functions";
import moment from "moment";

const OpenChat = ({ chatListOpen, onClose, userId, showTyping }) => {
  const { user } = useSelector((state) => state.authenticationReducer);
  const dispatch = useDispatch();
  // Reference for chat box
  const chatBoxRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom of the chat box whenever new data (chat messages) are added
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [chatListOpen]);

  const getNotification = () => {
    dispatch(getOpenUseNoticationAction({ data: { userId } }));
  };
  const refreshBuutton = () => {
    dispatch(
      getOpenUserChatListAction({
        data: { userId, isOpenUser: true },
        cb: getNotification,
      })
    );
  };
  const ChatRow = ({
    time,
    innerText,
    itsMe,
    index,
    userType,
    currentUserType,
    country
  }) => {
    const isShowTyping=index===showTyping
    return (
      <Fragment>
        <div
          className={`conversation-part--question ${
            itsMe ? "its-me text-right" : "clear-both "
          }`}
          style={{ marginTop: index == 0 ? "14%" : "0px" }}
        >
          <div className="avatar-wrapper" style={{ marginLeft: "0px" }}>
            {(!itsMe || isShowTyping) && <div className="avatar"></div>}
          </div>
          {!isShowTyping && <div
            className="same-row question-part"
            style={{
              backgroundColor: `${
                innerText?.includes("file-list") ? "#8c52ff " : ""
              }`,
            }}
          >
            <div
              className="question"
              dangerouslySetInnerHTML={{ __html: innerText }}
            ></div>
          </div>}
          {isShowTyping && (
            <div className="show-typing-effect">
              <img src="/images/typing.gif" />
            </div>
          )}
          {!isShowTyping && <span
            className={`comment ${itsMe ? "comment-right" : "comment-left"}`}
          >
            {formatMessageTime(time,country,innerText)}
            {user?.userType && (
              <Fragment>
                ({userType || currentUserType || user?.userType})
              </Fragment>
            )}
          </span>}
        </div>
        {itsMe && <p className="clear-both"></p>}
      </Fragment>
    );
  };

  return (
    <div className="chat-box" ref={chatBoxRef}>
      <div className="chat-header text-right pt-2">
        {user && (
          <span
            className="user-title"
            style={{ position: "absolute", left: "92px" }}
          >
            {chatListOpen?.name}
          </span>
        )}
        <div>
          {user && (
            <span
              className="pointer"
              style={{ position: "fixed", marginLeft: "-55px" }}
              onClick={refreshBuutton}
            >
              <Icons title={"refresh"} color="black" />
            </span>
          )}
          <span
            className="ml-3 pointer"
            style={{ position: "fixed", marginLeft: "-19px" }}
            onClick={onClose}
          >
            <Icons title={"cross"} color="black" />
          </span>
        </div>
      </div>
      {chatListOpen?.chatList?.map(
        (item, index) =>
          item?.message && (
            <ChatRow
              index={index}
              key={index}
              innerText={item?.message}
              time={item?.time}
              country={item?.country}
              currentUserType={item?.userType}
              userType={item?.sender?.userType || item?.userType}
              itsMe={
                (user
                  ? user?._id === item?.sender || user?._id === item?.sender?.id
                  : userId === item?.sender?.id || userId === item?.sender) ||
                (user?.userType &&
                  (item?.sender?.userType == "ADMIN" ||
                    item?.sender?.userType == "OPERATOR" ||
                    item?.sender?.userType == "SUPER_ADMIN" ||
                    item?.sender?.userType === "MANAGER"))
              }
            />
          )
      )}
    </div>
  );
};

export default OpenChat;
