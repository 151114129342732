import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PushNotificationApp from "../PushNotificationApp";
import PushNotificationListener from "../components/web-push/PushNotificationListener";
import CheckChatRouter from "../components/web-push/CheckChatRouter";
import DashboardSkeleton from "../components/skeleton/DashBoardSkeleton";


// Lazy Load Components
const Login = lazy(() => import("../view/auth/Login"));
const ManagerDashboard = lazy(() => import("../view/manager/ManagerDashboard"));
const Logout = lazy(() => import("../view/auth/Logout"));
const Error = lazy(() => import("../view/Error"));
const OperatorList = lazy(() => import("../view/manager/OperatorList"));
const ClientList = lazy(() => import("../view/manager/ClientList"));
const ExpertList = lazy(() => import("../view/manager/ExpertList"));
const LeadList = lazy(() => import("../view/manager/LeadList"));
const Orderlist = lazy(() => import("../view/manager/Orderlist"));
const Share = lazy(() => import("../../src/view/live-leads/component/ShareApplication"))
const OperatorDashboard = lazy(() =>
  import("../view/operator/Operator")
);
const LeadListOperator = lazy(() => import("../view/operator/LeadList"));
const OrderListOperator = lazy(() => import("../view/operator/OrderList"));
const ClientDashboard = lazy(() => import("../view/client/ClientDashboard"));
const LeadListClient = lazy(() => import("../view/client/LeadList"));
const OrderListClient = lazy(() => import("../view/client/OrderList"));
const OrderListExpert = lazy(() =>
  import("../view/experts/OrderListExpert")
);
const ExpertDashboard = lazy(() => import("../view/experts/ExpertDashboard"));
const ResetPassword = lazy(() => import("../view/auth/ResetPassword"));
const UpdatePassword = lazy(() => import("../view/auth/UpdatePassword"));
const SuperAdminDashboard = lazy(() =>
  import("../view/super-admin/SuperAdminDashboard")
);
const AccountDeleted = lazy(() => import('../components/DeleteAccount'))
const OperatorListSA = lazy(() => import("../view/super-admin/OperatorListSA"));
const ClientListSA = lazy(() => import("../view/super-admin/ClientListSA"));
const ExpertListSA = lazy(() => import("../view/super-admin/ExpertListSA"));
const LeadListSA = lazy(() => import("../view/super-admin/LeadListSA"));
const OrderlistSA = lazy(() => import("../view/super-admin/OrderlistSA"));
const ManagerList = lazy(() => import("../view/super-admin/ManagerList"));
const AdminList = lazy(() => import("../view/super-admin/AdminList"));
const ChatWithUsLazy = lazy(() => import("../view/chat/ChatWithUs")); 
const OpenLeadExpert = lazy(() =>
  import("../view/experts/OpenLeadExpert")
);
// const LiveUser = lazy(() => import("../view/super-admin/LiveUser"));
const MarketingSA = lazy(() => import("../view/super-admin/MarketingSA"));
const OrderReport = lazy(() => import("../components/reports/OrderReport"));
const UsersReport = lazy(() => import("../components/reports/UsersReport"));
const Queries = lazy(() => import("../components/queries/Queries"));
const RevenueReport = lazy(() =>
  import("../components/reports/RevenueReport")

);
const AndroidApp = lazy(() => import("../view/android/AndroidApp"));
const LoginWithGoogle = lazy(() =>
  import("../view/auth/social-auth/LoginWithGoogle")
);
const ClientDashboardDesign = lazy(() =>
  import("../view/android/ClientDashboardDesign")
);
const CouponCodeManagement = lazy(() =>
  import("../view/coupon-code/CouponCodeManagement")
);
const LiveLeads = lazy(() => import("../view/live-leads/LiveLeads"));

const LiveUserNewDashboard = lazy(() => import("../components/Live-chat-users/LiveUserNewDashboard"))

const Chat1 = lazy(() => import("../components/Chat1"))
// Fallback Loading Component
// const Loading = () => <div>Loading...</div>;

const ChatWithUs = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), 3000); // 1 second delay
    return () => clearTimeout(timer); // Cleanup on unmount
  }, []);

  if (!show) {
    return null; // Render nothing until delay is over
  }

  return (
    <Suspense fallback={<DashboardSkeleton />}>
      <ChatWithUsLazy />
    </Suspense>
  );
};

const CustomRouter = () => {
  return (
    <Router>
      <PushNotificationListener/>
      <Suspense fallback={<DashboardSkeleton />}>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/update-password/:resetId/:userId"
            element={<UpdatePassword />}
          />
          <Route path="/logout" element={<Logout />} />
          <Route path="/auth/google" element={<LoginWithGoogle />} />

          {/* Manager Routes */}
          <Route path="/manager/dashboard" element={<ManagerDashboard />} />
          <Route path="/manager/operators" element={<OperatorList />} />
          <Route path="/manager/clients" element={<ClientList />} />
          <Route path="/manager/experts" element={<ExpertList />} />
          <Route path="/manager/leads" element={<LeadList />} />
          <Route path="/manager/orders" element={<Orderlist />} />
          <Route path="/manager/live-users" element={<LiveUserNewDashboard />} />
          <Route path="/manager/marketings" element={<MarketingSA />} />
          <Route path="/manager/report/order" element={<OrderReport />} />
          <Route path="/manager/report/user" element={<UsersReport />} />
          <Route path="/manager/query" element={<Queries />} />
          <Route path="/manager/report/revenue" element={<RevenueReport />} />
          <Route
            path="/manager/androidpanel"
            element={<AndroidApp />}
          />
          <Route
            path="/manager/client/dashboard"
            element={<ClientDashboardDesign />}
          />
          <Route
            path="/manager/coupon-code"
            element={<CouponCodeManagement />}
          />
          <Route path="/manager/live/leads" element={<LiveLeads />} />
          <Route path="/manager/Share" element={<Share />} />  

          {/* Operator Routes */}
          <Route
            path="/operator/dashboard"
            element={<OperatorDashboard />}
          />
          <Route path="/operator/leads" element={<LeadListOperator />} />
          <Route
            path="/operator/orders"
            element={<OrderListOperator />}
          />
          <Route path="/operator/live-users" element={<LiveUserNewDashboard />} />
          <Route
            path="/operator/report/order"
            element={<OrderReport />}
          />
          <Route
            path="/operator/report/user"
            element={<UsersReport />}
          />
          <Route path="/operator/query" element={<Queries />} />
          <Route path="/operator/live/leads" element={<LiveLeads />} />
          <Route path="/operator/Share" element={<Share />} />  

          {/* Client Routes */}
          <Route path="/client/dashboard" element={<ClientDashboard />} />
          <Route path="/client/leads" element={<LeadListClient />} />
          <Route path="/client/orders" element={<OrderListClient />} />
          <Route path="/client/Share" element={<Share />} />  
          <Route path="/client/account-delete" element={<AccountDeleted />}/>

          {/* Expert Routes */}
          <Route path="/expert/dashboard" element={<ExpertDashboard />} />
          <Route path="/expert/leads" element={<OrderListExpert />} />
          <Route path="/expert/open-lead" element={<OpenLeadExpert />} />
          <Route path="/expert/Share" element={<Share />} />  

          {/* Super Admin Routes */}
          <Route
            path="/super-admin/dashboard"
            element={<SuperAdminDashboard />}
          />
          <Route
            path="/super-admin/operators"
            element={<OperatorListSA />}
          />
          <Route
            path="/super-admin/clients"
            element={<ClientListSA />}
          />
          <Route
            path="/super-admin/experts"
            element={<ExpertListSA />}
          />
          <Route path="/super-admin/managers" element={<ManagerList />} />
          <Route path="/super-admin/admins" element={<AdminList />} />
          <Route path="/super-admin/leads" element={<LeadListSA />} />
          <Route path="/super-admin/orders" element={<OrderlistSA />} />
          <Route path="/super-admin/live-users" element={<LiveUserNewDashboard />} />
          <Route
            path="/super-admin/marketings"
            element={<MarketingSA />}
          />
          <Route
            path="/super-admin/report/order"
            element={<OrderReport />}
          />
          <Route
            path="/super-admin/report/user"
            element={<UsersReport />}
          />
          <Route path="/super-admin/query" element={<Queries />} />
          <Route
            path="/super-admin/report/revenue"
            element={<RevenueReport />}
          />
          <Route
            path="/super-admin/androidpanel"
            element={<AndroidApp />}
          />
          <Route
            path="/super-admin/client/dashboard"
            element={<ClientDashboardDesign />}
          />
          <Route
            path="/super-admin/coupon-code"
            element={<CouponCodeManagement />}
          />
          <Route path="/super-admin/live/leads" element={<LiveLeads />} />
          <Route path="/super-admin/Share" element={<Share />} />  
          <Route path= "/super-admin/Chat1" element={<Chat1 />} />

          {/* Admin Routes */}
          <Route
            path="/admin/dashboard"
            element={<SuperAdminDashboard />}
          />
          <Route path="/admin/operators" element={<OperatorListSA />} />
          <Route path="/admin/clients" element={<ClientListSA />} />
          <Route path="/admin/experts" element={<ExpertListSA />} />
          <Route path="/admin/managers" element={<ManagerList />} />
          <Route path="/admin/leads" element={<LeadListSA />} />
          <Route path="/admin/orders" element={<OrderlistSA />} />
          {/* <Route path="/admin/live-users" element={<LiveUser />} /> */}
          <Route
            path="/admin/marketings"
            element={<MarketingSA />}
          />
          <Route
            path="/admin/report/order"
            element={<OrderReport />}
          />
          <Route
            path="/admin/report/user"
            element={<UsersReport />}
          />
          <Route path="/admin/query" element={<Queries />} />
          <Route
            path="/admin/report/revenue"
            element={<RevenueReport />}
          />
          <Route
            path="/admin/androidpanel"
            element={<AndroidApp />}
          />
          <Route
            path="/admin/client/dashboard"
            element={<ClientDashboardDesign />}
          />
          <Route
            path="/admin/coupon-code"
            element={<CouponCodeManagement />}
          />
          <Route path="/admin/Share" element={<Share />} />  
          <Route path="/admin/live-users" element={<LiveUserNewDashboard/>} />

          {/* <Route path="/chat" element={<ChatWithUs />} /> */}
          <Route path="/chat" element={<ChatWithUs />} />

          <Route path="/check-chat" element={<CheckChatRouter />} />
          <Route path="/marketings/live/leads" element={<LiveLeads />} />
          <Route path="/marketings/androidpanel" element={<AndroidApp />} />
          <Route path="/admin/live/leads" element={<LiveLeads />} />
          <Route path="/testing" element={<PushNotificationApp />}/>

          {/* Catch-All Route */}
          <Route path="*" element={<Error />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default CustomRouter;
