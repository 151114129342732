import React from "react";
import ChatWithUs from "../../view/chat/ChatWithUs";
import { useDispatch, useSelector } from "react-redux";
import {  closeOpenChatAction } from "../../services/action/common";
// import { getOpenUserChatListAction } from "../../services/action/common";

const OneToOneChat = () => {
  const {currentOpenChatUser}=useSelector((state)=>state.commonReducer);
  const dispatch=useDispatch();
  const closeChat=()=>{
    dispatch(closeOpenChatAction())
  }
  // console.log("currentOpenChatUser",currentOpenChatUser)
  // return (
  //   currentOpenChatUser && (
  //     <div className="admin-side-support-chat">
  //       <ChatWithUs
  //         ipCheck={false}
  //         openUserId={currentOpenChatUser?._id}
  //         onClose={closeChat}
  //       />
  //     </div>
  //   )
  // );
};

export default OneToOneChat;
