import {
  BookOpen,
  Headphones,
  Search,
  Star,
  TrendingUp,
  Sparkles,
  Clock,
  GraduationCap,
} from "lucide-react";

export const sourceList = [
  { label: "", value: "" },
  { label: "WhatsApp UK", value: "WhatsApp UK" },
  { label: "WhatsApp AUS", value: "WhatsApp AUS" },
  { label: "WhatsApp USA", value: "WhatsApp USA" },
  { label: "website", value: "website" },
  { label: "Collect Chat", value: "Collect Chat" },
  { label: "Direct Call", value: "Direct Call" },
  { label: "Meta", value: "Meta" },
  { label: "Aisensy", value: "Aisensy" },
  { label: "Google Form", value: "Google Form" },
  { label: "Referral", value: "Referral" },
  { label: "LinkedIn Pulse", value: "LinkedIn Pulse" },
  { label: "Other", value: "Other" },
];

export const userListOption = [
  { label: "OPERATOR", value: "OPERATOR" },
  { label: "EXPERT", value: "EXPERT" },
  { label: "MANAGER", value: "MANAGER" },
  { label: "CLIENT", value: "CLIENT" },
];

export const covertIntoLabelAndObject = (data) => {
  return data?.map((item) => ({ label: item, value: item }));
};

export const subjectList = [
  {
    label: "Mathematics",
    options: [
      { value: "Algebra", label: "Algebra" },
      { value: "Calculus", label: "Calculus" },
      { value: "Statistics", label: "Statistics" },
      { value: "Geometry", label: "Geometry" },
      { value: "Trigonometry", label: "Trigonometry" },
    ],
  },
  {
    label: "Science",
    options: [
      { value: "Physics", label: "Physics" },
      { value: "Chemistry", label: "Chemistry" },
      { value: "Biology", label: "Biology" },
      { value: "Environmental Science", label: "Environmental Science" },
      { value: "Earth Science", label: "Earth Science" },
    ],
  },
  {
    label: "Engineering",
    options: [
      { value: "Mechanical Engineering", label: "Mechanical Engineering" },
      { value: "Electrical Engineering", label: "Electrical Engineering" },
      { value: "Civil Engineering", label: "Civil Engineering" },
      { value: "Computer Engineering", label: "Computer Engineering" },
      { value: "Chemical Engineering", label: "Chemical Engineering" },
    ],
  },
  {
    label: "Computer Science",
    options: [
      { value: "Programming", label: "Programming (Python, Java, C++, etc.)" },
      {
        value: "Data Structures and Algorithms",
        label: "Data Structures and Algorithms",
      },
      { value: "Database Management", label: "Database Management" },
      { value: "Artificial Intelligence", label: "Artificial Intelligence" },
      { value: "Machine Learning", label: "Machine Learning" },
    ],
  },
  {
    label: "Business and Management",
    options: [
      { value: "Marketing", label: "Marketing" },
      {
        value: "Human Resource Management",
        label: "Human Resource Management",
      },
      { value: "Finance", label: "Finance" },
      { value: "Operations Management", label: "Operations Management" },
      { value: "Project Management", label: "Project Management" },
    ],
  },
  {
    label: "Economics",
    options: [
      { value: "Microeconomics", label: "Microeconomics" },
      { value: "Macroeconomics", label: "Macroeconomics" },
      { value: "Econometrics", label: "Econometrics" },
      { value: "International Economics", label: "International Economics" },
      { value: "Public Economics", label: "Public Economics" },
    ],
  },
  {
    label: "Accounting",
    options: [
      { value: "Financial Accounting", label: "Financial Accounting" },
      { value: "Managerial Accounting", label: "Managerial Accounting" },
      { value: "Auditing", label: "Auditing" },
      { value: "Taxation", label: "Taxation" },
      { value: "Corporate Accounting", label: "Corporate Accounting" },
    ],
  },
  {
    label: "Law",
    options: [
      { value: "Criminal Law", label: "Criminal Law" },
      { value: "Contract Law", label: "Contract Law" },
      { value: "International Law", label: "International Law" },
      { value: "Constitutional Law", label: "Constitutional Law" },
      { value: "Corporate Law", label: "Corporate Law" },
    ],
  },
  {
    label: "Nursing and Healthcare",
    options: [
      { value: "Nursing Practice", label: "Nursing Practice" },
      { value: "Medical-Surgical Nursing", label: "Medical-Surgical Nursing" },
      { value: "Pediatric Nursing", label: "Pediatric Nursing" },
      { value: "Public Health", label: "Public Health" },
      { value: "Pharmacology", label: "Pharmacology" },
    ],
  },
  {
    label: "Social Sciences",
    options: [
      { value: "Psychology", label: "Psychology" },
      { value: "Sociology", label: "Sociology" },
      { value: "Anthropology", label: "Anthropology" },
      { value: "Political Science", label: "Political Science" },
      { value: "History", label: "History" },
    ],
  },
  {
    label: "Humanities",
    options: [
      { value: "Literature", label: "Literature" },
      { value: "Philosophy", label: "Philosophy" },
      { value: "Art History", label: "Art History" },
      { value: "Cultural Studies", label: "Cultural Studies" },
      { value: "Religious Studies", label: "Religious Studies" },
    ],
  },
  {
    label: "Education",
    options: [
      { value: "Educational Psychology", label: "Educational Psychology" },
      { value: "Curriculum Development", label: "Curriculum Development" },
      { value: "Classroom Management", label: "Classroom Management" },
      { value: "Special Education", label: "Special Education" },
      {
        value: "Early Childhood Education",
        label: "Early Childhood Education",
      },
    ],
  },
  {
    label: "Environmental Studies",
    options: [
      { value: "Sustainable Development", label: "Sustainable Development" },
      { value: "Environmental Policy", label: "Environmental Policy" },
      { value: "Conservation Biology", label: "Conservation Biology" },
      { value: "Climate Change", label: "Climate Change" },
      { value: "Renewable Energy", label: "Renewable Energy" },
    ],
  },
  {
    label: "Languages",
    options: [
      { value: "English", label: "English" },
      { value: "Spanish", label: "Spanish" },
      { value: "French", label: "French" },
      { value: "German", label: "German" },
      { value: "Chinese", label: "Chinese" },
    ],
  },
  {
    label: "Media and Communication",
    options: [
      { value: "Journalism", label: "Journalism" },
      { value: "Public Relations", label: "Public Relations" },
      { value: "Media Studies", label: "Media Studies" },
      { value: "Digital Marketing", label: "Digital Marketing" },
      { value: "Film Studies", label: "Film Studies" },
    ],
  },
  {
    label: "Fine Arts",
    options: [
      { value: "Visual Arts", label: "Visual Arts" },
      { value: "Music", label: "Music" },
      { value: "Theater", label: "Theater" },
      { value: "Dance", label: "Dance" },
      { value: "Graphic Design", label: "Graphic Design" },
    ],
  },
  {
    label: "Architecture",
    options: [
      { value: "Architectural Design", label: "Architectural Design" },
      { value: "Urban Planning", label: "Urban Planning" },
      { value: "Landscape Architecture", label: "Landscape Architecture" },
      { value: "Interior Design", label: "Interior Design" },
      { value: "Building Technology", label: "Building Technology" },
    ],
  },
  {
    label: "Health Sciences",
    options: [
      { value: "Public Health", label: "Public Health" },
      { value: "Nutrition", label: "Nutrition" },
      { value: "Physical Therapy", label: "Physical Therapy" },
      { value: "Occupational Therapy", label: "Occupational Therapy" },
      { value: "Health Informatics", label: "Health Informatics" },
    ],
  },
  {
    label: "Sports Science",
    options: [
      { value: "Sports Management", label: "Sports Management" },
      { value: "Exercise Physiology", label: "Exercise Physiology" },
      { value: "Sports Psychology", label: "Sports Psychology" },
      { value: "Coaching", label: "Coaching" },
      { value: "Kinesiology", label: "Kinesiology" },
    ],
  },
  {
    label: "Human Resources",
    options: [
      { value: "Talent Management", label: "Talent Management" },
      { value: "Organizational Behavior", label: "Organizational Behavior" },
      { value: "Labor Relations", label: "Labor Relations" },
      {
        value: "Compensation and Benefits",
        label: "Compensation and Benefits",
      },
      {
        value: "Recruitment and Selection",
        label: "Recruitment and Selection",
      },
    ],
  },
];

// You can now pass this `options` array to the `SelectBox` component.

export const countryTimezones = {
  AF: "Asia/Kabul",
  AL: "Europe/Tirane",
  DZ: "Africa/Algiers",
  AD: "Europe/Andorra",
  AO: "Africa/Luanda",
  AG: "America/Antigua",
  AR: "America/Argentina/Buenos_Aires",
  AM: "Asia/Yerevan",
  AU: "Australia/Sydney",
  AT: "Europe/Vienna",
  AZ: "Asia/Baku",
  BS: "America/Nassau",
  BH: "Asia/Bahrain",
  BD: "Asia/Dhaka",
  BB: "America/Barbados",
  BY: "Europe/Minsk",
  BE: "Europe/Brussels",
  BZ: "America/Belize",
  BJ: "Africa/Porto-Novo",
  BO: "America/La_Paz",
  BA: "Europe/Sarajevo",
  BW: "Africa/Gaborone",
  BR: "America/Sao_Paulo",
  BN: "Asia/Brunei",
  BG: "Europe/Sofia",
  BF: "Africa/Ouagadougou",
  BI: "Africa/Bujumbura",
  CV: "Atlantic/Cape_Verde",
  KH: "Asia/Phnom_Penh",
  CM: "Africa/Douala",
  CA: "America/Toronto",
  CD: "Africa/Kinshasa",
  CG: "Africa/Brazzaville",
  CH: "Europe/Zurich",
  CL: "America/Santiago",
  CN: "Asia/Shanghai",
  CO: "America/Bogota",
  CR: "America/Costa_Rica",
  HR: "Europe/Zagreb",
  CU: "America/Havana",
  CY: "Asia/Nicosia",
  CZ: "Europe/Prague",
  DK: "Europe/Copenhagen",
  DJ: "Africa/Djibouti",
  DM: "America/Dominica",
  DO: "America/Santo_Domingo",
  EC: "America/Guayaquil",
  EG: "Africa/Cairo",
  SV: "America/El_Salvador",
  GQ: "Africa/Malabo",
  ER: "Africa/Asmara",
  EE: "Europe/Tallinn",
  SZ: "Africa/Mbabane",
  ET: "Africa/Addis_Ababa",
  FJ: "Pacific/Fiji",
  FI: "Europe/Helsinki",
  FR: "Europe/Paris",
  GA: "Africa/Libreville",
  GB: "Europe/London",
  GD: "America/Grenada",
  GE: "Asia/Tbilisi",
  GH: "Africa/Accra",
  GR: "Europe/Athens",
  GT: "America/Guatemala",
  GN: "Africa/Conakry",
  GW: "Africa/Bissau",
  GY: "America/Guyana",
  HT: "America/Port-au-Prince",
  HN: "America/Tegucigalpa",
  HU: "Europe/Budapest",
  IS: "Atlantic/Reykjavik",
  IN: "Asia/Kolkata",
  ID: "Asia/Jakarta",
  IR: "Asia/Tehran",
  IQ: "Asia/Baghdad",
  IE: "Europe/Dublin",
  IL: "Asia/Jerusalem",
  IT: "Europe/Rome",
  JM: "America/Jamaica",
  JP: "Asia/Tokyo",
  JO: "Asia/Amman",
  KZ: "Asia/Almaty",
  KE: "Africa/Nairobi",
  KI: "Pacific/Tarawa",
  KP: "Asia/Pyongyang",
  KR: "Asia/Seoul",
  KW: "Asia/Kuwait",
  KG: "Asia/Bishkek",
  LA: "Asia/Vientiane",
  LV: "Europe/Riga",
  LB: "Asia/Beirut",
  LS: "Africa/Maseru",
  LR: "Africa/Monrovia",
  LY: "Africa/Tripoli",
  LT: "Europe/Vilnius",
  LU: "Europe/Luxembourg",
  MG: "Indian/Antananarivo",
  MW: "Africa/Blantyre",
  MY: "Asia/Kuala_Lumpur",
  MV: "Asia/Male",
  ML: "Africa/Bamako",
  MT: "Europe/Malta",
  MH: "Pacific/Majuro",
  MQ: "America/Martinique",
  MR: "Africa/Nouakchott",
  MU: "Indian/Mauritius",
  MX: "America/Mexico_City",
  FM: "Pacific/Chuuk",
  MD: "Europe/Chisinau",
  MC: "Europe/Monaco",
  MN: "Asia/Ulaanbaatar",
  ME: "Europe/Podgorica",
  MA: "Africa/Casablanca",
  MZ: "Africa/Maputo",
  MM: "Asia/Yangon",
  NA: "Africa/Windhoek",
  NP: "Asia/Kathmandu",
  NL: "Europe/Amsterdam",
  NZ: "Pacific/Auckland",
  NI: "America/Managua",
  NE: "Africa/Niamey",
  NG: "Africa/Lagos",
  NU: "Pacific/Niue",
  NF: "Pacific/Norfolk",
  NO: "Europe/Oslo",
  OM: "Asia/Muscat",
  PK: "Asia/Karachi",
  PW: "Pacific/Palau",
  PA: "America/Panama",
  PG: "Pacific/Port_Moresby",
  PY: "America/Asuncion",
  PE: "America/Lima",
  PH: "Asia/Manila",
  PL: "Europe/Warsaw",
  PT: "Europe/Lisbon",
  QA: "Asia/Qatar",
  RE: "Indian/Reunion",
  RO: "Europe/Bucharest",
  RU: "Europe/Moscow",
  RW: "Africa/Kigali",
  SA: "Asia/Riyadh",
  SD: "Africa/Khartoum",
  SN: "Africa/Dakar",
  SG: "Asia/Singapore",
  SX: "America/Philipsburg",
  SK: "Europe/Bratislava",
  SI: "Europe/Ljubljana",
  SB: "Pacific/Guadalcanal",
  SO: "Africa/Mogadishu",
  ZA: "Africa/Johannesburg",
  SS: "Africa/Juba",
  ES: "Europe/Madrid",
  LK: "Asia/Colombo",
  SD: "Africa/Khartoum",
  SR: "America/Paramaribo",
  SZ: "Africa/Mbabane",
  SE: "Europe/Stockholm",
  CH: "Europe/Zurich",
  SY: "Asia/Damascus",
  TJ: "Asia/Dushanbe",
  TZ: "Africa/Dodoma",
  TH: "Asia/Bangkok",
  TG: "Africa/Lome",
  TK: "Pacific/Tokelau",
  TO: "Pacific/Tongatapu",
  TT: "America/Port_of_Spain",
  TN: "Africa/Tunis",
  TR: "Europe/Istanbul",
  TM: "Asia/Ashgabat",
  TV: "Pacific/Funafuti",
  UG: "Africa/Kampala",
  UA: "Europe/Kiev",
  AE: "Asia/Dubai",
  GB: "Europe/London",
  US: "America/New_York", // You can specify other US time zones if needed
  UY: "America/Montevideo",
  UZ: "Asia/Tashkent",
  VU: "Pacific/Efate",
  VE: "America/Caracas",
  VN: "Asia/Hanoi",
  WF: "Pacific/Wallis",
  EH: "Africa/El_Aaiun",
  YE: "Asia/Aden",
  ZM: "Africa/Lusaka",
  ZW: "Africa/Harare",
};

export const countryCodes = [
  { code: "+1", country: "USA" },
  { code: "+44", country: "UK" },
  { code: "+91", country: "India" },
  { code: "+86", country: "China" },
  { code: "+81", country: "Japan" },
  { code: "+49", country: "Germany" },
  { code: "+33", country: "France" },
  { code: "+7", country: "Russia" },
  { code: "+55", country: "Brazil" },
  { code: "+61", country: "Australia" },
  { code: "+34", country: "Spain" },
  { code: "+39", country: "Italy" },
  { code: "+1-242", country: "Bahamas" },
  { code: "+1-246", country: "Barbados" },
  { code: "+1-268", country: "Antigua and Barbuda" },
  { code: "+1-264", country: "Anguilla" },
  { code: "+1-784", country: "Saint Vincent and the Grenadines" },
  { code: "+52", country: "Mexico" },
  { code: "+54", country: "Argentina" },
  { code: "+57", country: "Colombia" },
  { code: "+64", country: "New Zealand" },
  { code: "+27", country: "South Africa" },
  { code: "+62", country: "Indonesia" },
  { code: "+63", country: "Philippines" },
  { code: "+66", country: "Thailand" },
  { code: "+60", country: "Malaysia" },
  { code: "+971", country: "United Arab Emirates" },
  { code: "+966", country: "Saudi Arabia" },
  { code: "+20", country: "Egypt" },
  { code: "+212", country: "Morocco" },
  { code: "+234", country: "Nigeria" },
  { code: "+31", country: "Netherlands" },
  { code: "+32", country: "Belgium" },
  { code: "+48", country: "Poland" },
  { code: "+47", country: "Norway" },
  { code: "+46", country: "Sweden" },
  { code: "+41", country: "Switzerland" },
  { code: "+351", country: "Portugal" },
  { code: "+90", country: "Turkey" },
  { code: "+380", country: "Ukraine" },
  { code: "+98", country: "Iran" },
  { code: "+82", country: "South Korea" },
  { code: "+84", country: "Vietnam" },
  { code: "+94", country: "Sri Lanka" },
  { code: "+358", country: "Finland" },
  { code: "+420", country: "Czech Republic" },
  { code: "+30", country: "Greece" },
  { code: "+64", country: "New Zealand" },
  { code: "+373", country: "Moldova" },
  { code: "+372", country: "Estonia" },
  { code: "+371", country: "Latvia" },
  { code: "+370", country: "Lithuania" },
  { code: "+356", country: "Malta" },
  { code: "+357", country: "Cyprus" },
  { code: "+995", country: "Georgia" },
  { code: "+374", country: "Armenia" },
  { code: "+359", country: "Bulgaria" },
  { code: "+382", country: "Montenegro" },
  { code: "+385", country: "Croatia" },
  { code: "+387", country: "Bosnia and Herzegovina" },
  { code: "+389", country: "North Macedonia" },
  { code: "+375", country: "Belarus" },
  { code: "+43", country: "Austria" },
  { code: "+36", country: "Hungary" },
  { code: "+420", country: "Czech Republic" },
  { code: "+372", country: "Estonia" },
  { code: "+40", country: "Romania" },
  { code: "+48", country: "Poland" },
  { code: "+380", country: "Ukraine" },
  { code: "+421", country: "Slovakia" },
  { code: "+385", country: "Croatia" },
  { code: "+386", country: "Slovenia" },
  { code: "+679", country: "Fiji" },
  { code: "+676", country: "Tonga" },
  { code: "+679", country: "Fiji" },
  { code: "+675", country: "Papua New Guinea" },
  { code: "+263", country: "Zimbabwe" },
  { code: "+254", country: "Kenya" },
  { code: "+256", country: "Uganda" },
  { code: "+250", country: "Rwanda" },
  { code: "+255", country: "Tanzania" },
  { code: "+258", country: "Mozambique" },
  { code: "+223", country: "Mali" },
  { code: "+225", country: "Ivory Coast" },
  { code: "+221", country: "Senegal" },
  { code: "+216", country: "Tunisia" },
  { code: "+243", country: "Democratic Republic of the Congo" },
  { code: "+233", country: "Ghana" },
  { code: "+263", country: "Zimbabwe" },
  { code: "+992", country: "Tajikistan" },
  { code: "+993", country: "Turkmenistan" },
  { code: "+996", country: "Kyrgyzstan" },
  { code: "+998", country: "Uzbekistan" },
  { code: "+968", country: "Oman" },
  { code: "+964", country: "Iraq" },
  { code: "+977", country: "Nepal" },
  { code: "+975", country: "Bhutan" },
  { code: "+880", country: "Bangladesh" },
  { code: "+856", country: "Laos" },
  { code: "+95", country: "Myanmar" },
  { code: "+66", country: "Thailand" },
  { code: "+855", country: "Cambodia" },
  { code: "+673", country: "Brunei" },
  { code: "+65", country: "Singapore" },
  { code: "+964", country: "Iraq" },
  { code: "+967", country: "Yemen" },
  { code: "+962", country: "Jordan" },
  { code: "+253", country: "Djibouti" },
  { code: "+265", country: "Malawi" },
  // Add more country codes as needed
];

export const MobileServiceIcons = (index) => {
  switch (index) {
    case 0:
      return <BookOpen className="h-6 w-6 text-white" />;
    case 1:
      return <Search className="h-6 w-6 text-white" />;
    case 2:
      return <TrendingUp className="h-6 w-6 text-white" />;
    case 3:
      return <Star className="h-6 w-6 text-white" />;
    case 4:
      return <Headphones className="h-6 w-6 text-white" />;
    case 5:
      return <GraduationCap className="h-6 w-6 text-white" />;
    default:
      return "";
  }
};

export const MobileAppPremiumServices = (index) => {
  switch (index) {
    case 0:
      return <Sparkles className="h-6 w-6 text-white" />;
    case 1:
      return <Clock className="h-6 w-6 text-white" />;
    case 2:
      return <GraduationCap className="h-6 w-6 text-white" />;
    default:
      return "";
  }
};

export const getStatusStylesChat = (status) => {
  const styles = {
    missed: { border: 'border-l-red-500', badge: 'bg-[#1a2d4a] text-red-400', text: 'Missed' },
    confirmed: { border: 'border-l-green-500', badge: 'bg-[#1a2d4a] text-green-400', text: 'Confirmed' },
    pending: { border: 'border-l-yellow-500', badge: 'bg-[#1a2d4a] text-yellow-400', text: 'Pending' },
  };
  return styles[status] || { border: 'border-l-gray-500', badge: 'bg-[#1a2d4a] text-gray-400', text: status };
};