import { Button } from '@mui/material'
import React from 'react'
import { Plus, Settings } from 'react-feather';
import Icons from '../Icons';


const Buttons = ({disabled, style={},isLoading,variant="contained",title,icon,size="small",onClick,iconClass,className=""}) => {
    if(icon){
        switch(icon){
          case "setting":
                return <Button className={className} disabled={disabled} onClick={onClick} variant={variant} startIcon={isLoading?<Icons title={"loader"} className={iconClass}/>:<Settings />} size={size}>{title}</Button>;
            case "plus":
                return <Button className={className} disabled={disabled} onClick={onClick} variant={variant} startIcon={<Plus />} size={size}>{title}</Button>;
                default : <Button className={className} disabled={disabled} onClick={onClick} variant={variant} startIcon={<Plus />} size={size}>{title}</Button>;
        }
    }

  return (
    <Button style={style} disabled={disabled} onClick={onClick} variant={variant} size={size}> {isLoading?<Icons title={"loader"} size={17}/>: title} </Button>
  )
}

export default Buttons