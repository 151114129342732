import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUserAction } from "../../services/action/authentication";
import { useLocation, useNavigate } from "react-router-dom";

const CheckChatRouter = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate=useNavigate();

  // Parse the query string
  const queryParams = new URLSearchParams(location.search);
  const visitorId = queryParams.get("visitorId");
  const leadId = queryParams.get("leadId");
  const leadForm=queryParams.get("leadForm");
  useEffect(() => {
    if (visitorId || leadId || leadForm) {
      dispatch(
        getUserAction({
          cb: (res) => {
            if(leadId){
                navigate(`/${res?.user?.userType?.toLowerCase()}/dashboard?leadId=${leadId}`)
            }else if(visitorId){
                navigate(`/${res?.user?.userType?.toLowerCase()}/dashboard?visitorId=${visitorId}`)
            }else if(leadForm){
                navigate(`/${res?.user?.userType?.toLowerCase()}/live/leads`)
            }
            
          },
        })
      );
    }
  }, [dispatch, leadId, visitorId,navigate,leadForm]);
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-100 to-blue-50">
      <div className="text-center">
        <div className="inline-block animate-pulse">
          <div className="w-24 h-24 rounded-full border-4 border-blue-500 border-t-transparent animate-spin"></div>
        </div>
        <h1 className="mt-8 text-3xl md:text-4xl font-bold text-blue-900">
          Loading...
        </h1>
        <p className="mt-4 text-lg md:text-xl text-blue-700">
          Please wait while we prepare your experience
        </p>
      </div>
      <div className="absolute inset-0 bg-grid-blue-500/[0.05] -z-10"></div>
    </div>
  );
};

export default CheckChatRouter;
