import {   take, takeLatest } from "redux-saga/effects";
import { sagaEngine } from "./sagaEngine";
import { appActions } from "../action";

function* mySaga() {
  yield takeLatest(appActions.LOGIN, sagaEngine);
  yield takeLatest(appActions.GET_USER,sagaEngine)
  yield takeLatest(appActions.GET_ALL_USER_ACTION,sagaEngine)
  yield takeLatest(appActions.CREATE_USER,sagaEngine)
  yield takeLatest(appActions.DELETE_USER_MANAGER,sagaEngine)
  yield takeLatest(appActions.UPDATE_USER_MANAGER,sagaEngine)
  yield takeLatest(appActions.UPLOAD_IMAGE,sagaEngine)
  yield takeLatest(appActions.GET_CLIENT_LIST_OPTION_MANAGER,sagaEngine)
  yield takeLatest(appActions.GENERATE_LEAD_MANAGER,sagaEngine)
  yield takeLatest(appActions.GET_GENERATE_LEAD_ADMIN,sagaEngine)
  yield takeLatest(appActions.UPDATE_LEAD_MANAGER,sagaEngine)
  yield takeLatest(appActions.START_CHAT,sagaEngine)
  yield takeLatest(appActions.GET_CHAT,sagaEngine)
  yield takeLatest(appActions.GET_DASHBOARD,sagaEngine)
  yield takeLatest(appActions.GET_EXPERT_LIST_OPTION, sagaEngine)
  yield takeLatest(appActions.GET_NOTIFICATION_LIST,sagaEngine)
  yield takeLatest(appActions.UPDATE_NOTIFICATION_STATUS,sagaEngine)
  yield takeLatest(appActions.GENERATE_PAYMENT_LINK,sagaEngine)
  yield takeLatest(appActions.GET_COUNTRY_LIST,sagaEngine)
  yield takeLatest(appActions.UPLOAD_AUDIO,sagaEngine)
  yield takeLatest(appActions.CHANGE_CHAT_VISIBITY,sagaEngine)
  yield takeLatest(appActions.RESET_PASSWORD,sagaEngine)
  yield takeLatest(appActions.UPDATE_PASSWORD,sagaEngine)
  yield takeLatest(appActions.SENT_PAYMENT_LINK_WHATSAPP,sagaEngine)
  yield takeLatest(appActions.CHANGE_CALL_HIPPO_PERMISSION,sagaEngine)
  yield takeLatest(appActions.GET_OPERATOR_MANAGER_LIST_ACTION,sagaEngine)
  yield takeLatest(appActions.ORDER_DELIVERED_ACTION, sagaEngine)
  yield takeLatest(appActions.SEND_BALANCED_AMOUNT_TO_CLIENT,sagaEngine)
  yield takeLatest(appActions.REQUEST_FOR_ASSIGN_LEAD,sagaEngine)
  yield takeLatest(appActions.GET_ALL_LIVE_USER_LIST,sagaEngine)
  yield takeLatest(appActions.GET_OPEN_USER_CHAT_LIST,sagaEngine)
  yield takeLatest(appActions.GET_OPEN_NOTIFICATION,sagaEngine)
  yield takeLatest(appActions.GET_REPORTS,sagaEngine)
  yield takeLatest(appActions.GET_LEAD_DETAILS,sagaEngine)
  yield takeLatest(appActions.GET_EXPERT_QUERIES,sagaEngine)
  yield takeLatest(appActions.UPDATE_QUERY_STATUS,sagaEngine)
  yield takeLatest(appActions.UPDATE_CURRENCY,sagaEngine)
  yield takeLatest(appActions.GET_CURRENCY_LIST,sagaEngine)
  yield takeLatest(appActions.UNASSIGNED_LEAD,sagaEngine)
  yield takeLatest(appActions.VERIFY_GOOGLE_TOKEN,sagaEngine)
  yield takeLatest(appActions.SIGNUP,sagaEngine)
  yield takeLatest(appActions.GET_ORDER_ACTIVITY,sagaEngine)
  yield takeLatest(appActions.SET_PENALTY,sagaEngine)
  yield takeLatest(appActions.GET_WELCOME_SCREEN_DATA,sagaEngine)
  yield takeLatest(appActions.ON_UPDATE_ANDROID_DESIGN,sagaEngine)
  yield takeLatest(appActions.UPDATE_REFERAL_AMOUNT,sagaEngine)
  yield takeLatest(appActions.GET_REFERAL_AMOUNT,sagaEngine)
  yield takeLatest(appActions.VERIFY_COUPON_CODE,sagaEngine)
  yield takeLatest(appActions.UPDATE_CLIENT_WALLET,sagaEngine)
  yield takeLatest(appActions.ALL_LIVE_LEADS,sagaEngine)
  yield takeLatest(appActions.GET_LIVE_LEADS_DETAILS,sagaEngine)
  yield takeLatest(appActions.LIVE_ADS_STATUS_UPDATE,sagaEngine)
  yield takeLatest(appActions.GET_LEAD_ACTIVITY,sagaEngine)
  yield takeLatest(appActions.GET_REPORT_ORDER_LIST,sagaEngine)
  yield takeLatest(appActions.GET_USER_DETAILS,sagaEngine)
  yield takeLatest(appActions.GET_USER_REPORTLIST,sagaEngine)
  yield takeLatest(appActions.GET_LIVE_USER_TITLE_COUNT,sagaEngine)
}


export default mySaga;
