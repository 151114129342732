import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PushNotificationListener = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        const { action, url } = event.data;

        if (action === 'navigate' && url) {
          navigate(url); // Use React Router to navigate to the target URL
        }
      });
    }
  }, [navigate]);

  return null;
};

export default PushNotificationListener;
