import { useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

const  PushNotificationApp = () => {
  const serverUrl =
    process.env.REACT_APP_API_URL || "https://portal.be.clickinpedia.io/"; // Your backend server URL
  const { user } = useSelector((state) => state.authenticationReducer);
  const checkSubscription=localStorage.getItem("web-subscription");

  useEffect(() => {
    // Register Service Worker
    if (user && !checkSubscription) {
      if ("serviceWorker" in navigator && "PushManager" in window) {
        navigator.serviceWorker
          .register("/service-worker.js")
          .then(async (registration) => {
            console.log("Service Worker Registered:", registration);

            // Get VAPID Public Key from backend
            const { data } = await axios.get(`${serverUrl}vapidPublicKey`);
            const vapidPublicKey = data.publicKey;

            // Convert the VAPID public key to a Uint8Array
            const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);

            // Subscribe to Push Notifications
            const subscription = await registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: convertedVapidKey,
            });

            // console.log("Push Subscription:", subscription);

            // Prepare Authorization headers
            const token = localStorage.getItem("token");
            const config = {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            };

            // Send subscription to the backend
            await axios.post(`${serverUrl}subscribe`, subscription, config);
            const tempSubscription = JSON.stringify(subscription);
            localStorage.setItem("web-subscription", tempSubscription);
            // console.log("Subscription saved on server");
          })
          .catch((error) => console.error("Service Worker Error:", error));
      }
    }
  }, [user,checkSubscription]);

  // Helper function to convert VAPID key
  const urlBase64ToUint8Array = (base64String) => {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, "+")
      .replace(/_/g, "/");
    const rawData = window.atob(base64);
    return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)));
  };

  return null;
};

export default PushNotificationApp;
