import React, { Fragment, useEffect, useState } from "react";
import OpenChat from "../../components/open-chat/OpenChat";
import Icons from "../../components/utils/Icons";
import { useDispatch, useSelector } from "react-redux";
import socket from "../../components/socket/socket";
import { getOpenUserChatListAction } from "../../services/action/common";
import GetVisitorDetails from "../../components/open-chat/components/GetVisitorDetails";
import OpenNotification from "./OpenNotification";
import InitialChatSelection from "../../components/open-chat/components/InitialChatSelection";
import ActionChatOpen from "./ActionChatOpen";
import moment from "moment";
import UploadFile from "./UploadFile";
import { validateEmail, validateName, validatePhoneNumber } from "../../components/utils/functions/functions";
import { Tooltip } from "@mui/material";
// import InitialChatSelection from "../../components/open-chat/components/InitialChatSelection";

const skipTitle = "Skip for now";
const ChatWithUs = ({ onClose, ipCheck = true, openUserId = false }) => {
  // const {user}=useSelector((state)=>state.authenticationReducer)
  const [userId, setUserId] = useState("");
  const [chatListOpen, setOpenChatList] = useState({
    chatList: [],
    name: "",
  });
  const [openChatBox, setOpenChatBox] = useState(false);

  const { openUserChatList } = useSelector((state) => state.commonReducer);
  const { user } = useSelector((state) => state.authenticationReducer);
  const [userInputType, setUserInputType] = useState("REQUEST_MESSAGE");
  const [showInput, setShowInput] = useState("MESSAGE");
  const [showInitialMessage, setShowInitialMessage] = useState(false);
  const [showTypingEffect, setShowTypingEffect] = useState(false);
  const [showSkipButton, setShowSkipButton] = useState(false);
  const [numberError, setNumberError] = useState("");
  const [emailError,setEmailError]=useState("")
  const [nameError,setNameError]=useState("");
  const [number, setNumber] = useState({
    number: "",
  });

  const [message, setMessaage] = useState("");
  const dispatch = useDispatch();

  const location=window.location
  const params = new URLSearchParams(location.search);
  const customButton = params.get("customButton"); 
  const onSubmit = (newMessage = "", firstMessage) => {
    if(showInput=="NUMBER"){
      if (!validatePhoneNumber(number.number)) {
        if(newMessage!=skipTitle){
          setNumberError("Invalid Number");
          return false;
        }else{
          setNumberError("");
        }
      } else {
        setNumberError("");
      }
    }
   
    if(showInput=="EMAIL"){
      let tempEmail=newMessage || message;
      if (!validateEmail(tempEmail)) {
        if(newMessage!=skipTitle){
          setEmailError("Invalid Email");
          return false;
        }else{
          setEmailError("");
        }
      } else {
        setEmailError("");
      }
    }

     
    if(showInput=="NAME"){
      let tempEmail=newMessage || message;
      if (!validateName(tempEmail)) {
        if(newMessage!=skipTitle){
          setNameError("Invalid Name");
          return false;
        }else{
          setNameError("");
        }
      } else {
        setNameError("");
      }
    }

      const time =  moment();
      if (newMessage) {
        setOpenChatBox(true);
      }
      if (openUserId) {
        const obj = {
          userId: openUserId,
          sender: user?._id,
          type: userInputType,
          time,
          message: newMessage || message,
        };

        socket.emit("open_chat_backend", obj);
        setOpenChatList((prev) => ({
          ...prev,
          chatList: [...prev.chatList, obj],
        }));
    } else {
      if (firstMessage) {
        setShowTypingEffect(1);
        setTimeout(() => {
          setShowTypingEffect(false);
        }, [3000]);
      }

      if (showInput == "NUMBER") {
        socket.emit("open_chat_user", {
          type: newMessage == skipTitle ? "MESSAGE" : showInput,
          value: newMessage == skipTitle ? newMessage : number.number,
          sender: userId,
          time,
          firstMessage,
        });
        setOpenChatList((prev) => ({
          ...prev,
          chatList: [
            ...prev.chatList,
            {
              message: newMessage || number.number ,
              sender: userId,
            },
          ],
        }));
      } else {
        socket.emit("open_chat_user", {
          type:
            message == skipTitle || newMessage == skipTitle
              ? "MESSAGE"
              : showInput,
          value: newMessage || message,
          sender: userId,
          name: chatListOpen?.name,
          time,
          firstMessage,
        });
        setOpenChatList((prev) => ({
          ...prev,
          chatList: [
            ...prev.chatList,
            {
              message: message || newMessage,
              sender: userId,
            },
          ],
        }));
      }
    }
    setShowSkipButton(false);
    setMessaage("");
    setNumber({});
    setShowInput("MESSAGE");
    setUserInputType("REQUEST_MESSAGE");
  };

  useEffect(() => {
    socket.on("exits_user", (existUser) => {
      setUserId(existUser?.userId);
    });
    return () => {
      socket.off("exits_user");
    };
  }, []);

  useEffect(() => {
    if (openUserId) {
      dispatch(getOpenUserChatListAction({ data: { userId: openUserId } }));
    }
  }, [openUserId]);

  useEffect(() => {
    if (openUserChatList) {
      setOpenChatList(openUserChatList);
    }
  }, [openUserChatList]);

  useEffect(() => {
    if (chatListOpen?.chatList?.length) {
      if (openChatBox) {
        setShowInitialMessage(false);
      }
    } 
    // else {
    //   setShowInitialMessage(true);
    // }
  }, [chatListOpen]);
  useEffect(() => {
    socket.on("user_message_recieve", (messages) => {
      if(messages?.isShowTyping){
        setShowTypingEffect(3);
        setTimeout(() => {
          setShowTypingEffect(false);
        }, [3000]);
      }
      if (messages?.requestType === "REQUEST_NUMBER") {
        setShowInput("NUMBER");
      } else if (messages?.requestType === "REQUEST_EMAIL") {
        setTimeout(() => {
          setShowSkipButton(true);
        }, [4000]);
        setShowInput("EMAIL");
      } else if (messages?.requestType === "REQUEST_NAME") {
        setTimeout(() => {
          setShowSkipButton(true);
        }, [4000]);
        setShowInput("NAME");
      } else {
        setShowInput("MESSAGE");
      }
      setOpenChatList((prev) => ({
        ...prev,
        chatList: [...prev.chatList, messages],
      }));
    });
    return () => {
      socket.off("user_message_recieve");
    };
  }, []);

  useEffect(() => {
    if (openUserId && !ipCheck) {
      setOpenChatBox(openUserId);
    }
  }, [openUserId]);

  const closeChatBox = () => {
    setOpenChatBox(false);
    onClose && onClose();
  };

  useEffect(() => {
    socket.on("recieve_message_from_open_chat", (currentUserMessage) => {
      if (openUserId == currentUserMessage?.sender) {
        setOpenChatList((prev) => {
          return { ...prev, chatList: [...prev.chatList, currentUserMessage] };
        });
      }
    });
    return () => {
      socket.off("recieve_message_from_open_chat");
    };
  }, []);

  useEffect(() => {
    // Create a MutationObserver to watch for changes
    const observer = new MutationObserver((mutations) => {
      let hideChat = document.querySelector(".hide-open-chat-are");

      const action = hideChat ? "hideChat" : "showChat";
      window.parent.postMessage({ action }, "*");
    });
    observer.observe(document.body, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    const initialHideChat = document.querySelector(".hide-open-chat-are");
    window.parent.postMessage(
      { action: initialHideChat ? "hideChat" : "showChat" },
      "*"
    );

    // Cleanup observer on unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(()=>{
    if(customButton && userId){
     onSubmit("Hi",true)
    }
  },[customButton,userId])

  return (
    <div
      className={`open-chat-area ${
        openChatBox || showInitialMessage ? "" : "hide-open-chat-are"
      }`}
    >
      {ipCheck && <GetVisitorDetails />}
      {/* {chatListOpen?.chatList?.length < 1 &&
        !user?.userType &&
        userId &&
        openUserChatList && ( */}
      {showInitialMessage &&
        !user?.userType &&
        !chatListOpen?.chatList?.length && (
          <InitialChatSelection
            setShowInitialMessage={setShowInitialMessage}
            onSelect={(newMessage, firstTime) =>
              onSubmit(newMessage, firstTime)
            }
          />
        )}
      {/* )} */}
      {openChatBox &&
        (chatListOpen?.chatList?.length > 0 || user?.userType) && (
          <Fragment>
            <OpenChat
              chatListOpen={chatListOpen}
              onClose={closeChatBox}
              userId={userId || openUserId}
              showTyping={showTypingEffect}
            />
            <div className="bottom-input">
              {(showInput === "NUMBER" ||
                showInput === "EMAIL" ||
                showInput === "NAME") &&
                !showTypingEffect &&
                showSkipButton && (
                  <span
                    className="skip-message-btn"
                    onClick={() => onSubmit(skipTitle)}
                  >
                    Skip for now
                  </span>
                )}
              <div className="number-input-group">
                {showInput === "NUMBER" && !showTypingEffect && !user && (
                  <Fragment>
                    <input
                      type="number"
                      value={number.number}
                      onChange={(e) =>
                        setNumber({ ...number, number: e.target.value })
                      }
                      placeholder="Enter your contact number"
                      onKeyDown={(e) => {
                        if (e.key == "Enter") {
                          onSubmit()
                      }}
                    }
                      className={`form-control number-input ${
                        numberError ? "text-danger" : ""
                      }`}
                    />
                  </Fragment>
                )}
                {showInput === "EMAIL" && !user && (
                  <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessaage(e.target.value)}
                    placeholder="Enter your Email Id"
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        onSubmit();
                      }
                    }}
                    className={`form-control number-input ${emailError ?"text-danger":""}`}                  />
                )}
                {showInput === "NAME" && !user && (
                  <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessaage(e.target.value)}
                    placeholder="Enter Your Name"
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        onSubmit();
                      }
                    }}
                    className={`form-control number-input ${nameError?"text-danger":""}`}
                  />
                )}

                {(showInput === "MESSAGE" || showTypingEffect || user) && (
                  <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessaage(e.target.value)}
                    placeholder={`Write ${
                      userInputType === "REQUEST_MESSAGE"
                        ? "message"
                        : userInputType
                    } here...`}
                    className="form-control number-input"
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        onSubmit();
                      }
                    }}
                  />
                )}
                <Tooltip title={numberError || emailError || nameError}>
                  <span className="send-icon" onClick={() => 
                    
                    onSubmit("")}>
                    <Icons title={"send-icon"} color="#cccccc" size={25} />
                  </span>
                </Tooltip>
                <UploadFile onSubmit={onSubmit} />
                {(user?.userType == "ADMIN" ||
                  user?.userType == "SUPER_ADMIN" ||
                  user?.userType == "MANAGER" ||
                  user?.userType == "OPERATOR") && (
                  <Fragment>
                    <ActionChatOpen setUserInputType={setUserInputType} />
                  </Fragment>
                )}
              </div>
            </div>
          </Fragment>
        )}
      {!user?.userType && (
        <div
          className="main-agent-avatar-open"
          onClick={() => {
            if (chatListOpen?.chatList?.length) {
              setOpenChatBox(true);
            } else {
              setShowInitialMessage(true);
            }
          }}
        >
          <OpenNotification
            userId={userId || openUserId}
            isOpenChat={openChatBox}
            setOpenChatBox={setOpenChatBox}
          />
        </div>
      )}
    </div>
  );
};

export default ChatWithUs;
